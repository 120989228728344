/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import SvgInline from '@latitude/svg-inline';
import { Heading4 } from '@latitude/heading';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import Faq from '@/components/Faq/Faq';
import { Box } from '@/components/Box/Box';
import HowToApply from '@latitude/how-to-apply';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/travel-loans.json';
import PLData from '@/data/pages/personal-loan.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import { FeaturesSlider } from '@latitude/features-slider';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '../utils/constants';
import { PLEligibilityCriteria } from '../components/PersonalLoanPageContent/PersonalLoanPageContent';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import footerData from '@/data/json/footer.json';
import heroImage from '../images/hero/travel-loan.webp';
import Button from '../components/Button/Button';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import Section from '@latitude/section';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import ProofPointCollection from '@/components/lab-components/ProofPointCollection';
import { CONTENTFUL_COMPONENTS, PL_APPLY_CTA_TRACK_ID } from '@/utils/constants';
import ProductComparisonSection from '@/components/PersonalLoanPageContent/ProductComparisonSection';
import CardCollection from '@/components/lab-components/CardCollection';
import articleTransformer from '@/utils/articleTransformer';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;
const CTA_BUTTON_LABEL = 'Get my rate estimate';
const COLOUR_PALE_BLUE = '#E5F6FF';

const titles = [
  "Travel Done Better",
  "Bucket-List Trips: How Much Do These Common Wish-List Holidays Cost?",
  "Planning Your Next Trip? 7 Unusual Destinations You'll Love"
];


const TravelLoansPage = props => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};



  const featureSliderJson = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PageData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'travel'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);


  const articlesData = articleTransformer(titles);
  return (
    <Layout
      location={props.location}
      canonical= 'https://www.latitudefinancial.com.au/travel-loans/'
      metaDesc={PageData.metaDesc}
      title={PageData.title}
      customFooter={footerData}
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={'Travel Loans'}
              subTitle={
                <>
                  Ready for your next adventure?
                  <br />
                  Take control of your finances. Apply online and get a response
                  in 60 seconds.
                </>
              }
              pageImage={heroImage}
              pageImageAlt="travel loan"
            >
              <div className="text-white row">
                <div className="text-left col-6 col-md-5">
                  <HomeButton
                    href={applyUrl}
                    trackId="travel-loans-button"
                    trackEventData={{
                      label: CTA_BUTTON_LABEL,
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    {CTA_BUTTON_LABEL}
                  </HomeButton>
                </div>
                <div className="p-0 text-center col-1">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="pt-1 text-left col-5 col-md-6 padding-right HeroContent__insettext">
                  Find out your estimated interest rate in 2 minutes
                </div>
              </div>
            </PageHeaderSection>
          )}

          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:10;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText={CTA_BUTTON_LABEL}
              offsetElem="[data-sticky-navigation-offset]"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              {...state?.inPageNavData?.[0]}
            />
          </div>


          <FeaturesSlider
             css={`
                background-color: #e6e6e6;
                && ul {
                  text-align: left;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    padding: 2px 20px 20px 20px;
                    text-align: center;
                    list-style-position: inside;
                  }
                }
                && h4 {
                  text-align: left;
                  font-size: 32px;
                  line-height: 36px;
                  margin: 0px;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    text-align: center;
                  }
                }
                &&.lfs-features-slider {
                  padding: 32px 0px;
                }
                && h4 > p {
                  padding: 0px;
                  margin: 0px;
                }
                && div.lfs-card-icon {
                  padding: 0;
                  width: 120px !important;
                  height: 120px !important;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    margin-bottom: 0px;
                  }
                }
                && svg {
                  border-radius: 50%;
                  background-color: white;
                  padding: 0px 23px;
                }
                && .lfs-card-text {
                  @media (max-width: ${BREAKPOINT.MD}) {
                    margin-top: 0px;
                  }
                }
                && .lfs-card {
                  @media (max-width: ${BREAKPOINT.MD}) {
                    gap: 16px;
                  }
                }
              `}
            key={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            id="why-us"
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .featureCards
            }
          />

          <Section
            id="repayment-calculator"
            heading="Get an estimate of your repayments"
            css={`
              background-color: ${COLOUR_PALE_BLUE};
              padding-top: 32px;
              padding-bottom: 32px;
              && h2.text-center {
                text-align: left !important;
                font-size: 32px;
                line-height: 36px;
                margin-top: 0px;
                margin-bottom: 30px;
              }
            `}
          >
            <PersonalLoanCalculator
              loans1
              ctaButtonLabel={CTA_BUTTON_LABEL}
              applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
            />
          </Section>

          <ProductComparisonSection
            id="compare-products"
            heading="Compare our fixed and variable loan options to find the right fit"
          />


          <ProofPointCollection  
            id="calculators-and-tools"
            data={
              state?.proofPointCollectionData?.[0] || PageData?.contentful?.proofPointCollectionData?.[0]
            }
          />

          <CardCollection
            id="applying-is-simple"
            data={
              state?.cardCollectionData?.[0] ||
              PageData?.contentful?.applyingIsSimple?.[0]
            }
          />

        <CardCollection data={state?.cardCollectionData?.[1] || articlesData } category="text-link"/>

          <Faq data={PageData.faq} {...state?.faqData?.[0]} />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
            css={`
              .important-info svg {
                stroke-width: unset;
              }
              .important-info__header > h3 {
                @media (min-width: 992px) {
                  margin: 0 0 0 6px;
                }
              }
            `}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "Travel Done Better"
              "Bucket-List Trips: How Much Do These Common Wish-List Holidays Cost?"
              "Planning Your Next Trip? 7 Unusual Destinations You'll Love"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

export default TravelLoansPage;
